import React from "react";
import Slider from "react-slick";

function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="testimonial-area testimonial-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Testimonials</h5>
              <h2 className="title">Our Happy Customers</h2>
              <p>
                I never thought I'd meet my life partner online, but
                Anandathirumanam proved me wrong. Their commitment to
                authenticity and quality matches helped me find the love of my
                life.
              </p>
            </div>
          </div>
        </div>
        <Slider className="row testimonial-active" {...settings}>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="/img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ Anandathirumanam made finding my life partner an absolute
                  breeze! The personalized matchmaking service ensured that I
                  was connected with someone who not only shares my values but
                  also complements my personality perfectly. I couldn't be
                  happier with the results! ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Priya S</h5>
                  <span>Client</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="/img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ I was skeptical about online matrimonial services, but
                  Anandathirumanam exceeded all my expectations. The platform is
                  user-friendly, and the team behind it is incredibly
                  supportive. Thanks to their efforts, I found my soulmate, and
                  we're looking forward to a blissful journey together. ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Rajesh K</h5>
                  <span>Client</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="/img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ Anandathirumanam stands out in the sea of matrimonial
                  platforms for its attention to detail and commitment to
                  quality matches. The team took the time to understand my
                  preferences and priorities, and as a result, I found someone
                  who is not just my partner but also my best friend. Highly
                  recommended! ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Meera R</h5>
                  <span>Client</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="/img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ I had tried other matrimonial services before, but none of
                  them came close to the experience I had with Anandathirumanam.
                  From the comprehensive profile verification process to the
                  prompt customer support, everything about this platform
                  screams professionalism. Thanks to them, I'm now happily
                  married! ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Arunkumar G</h5>
                  <span>Client</span>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonial;
