import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <Link to="/">
                    <img src="/img/logo/logo.png" alt="" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>
                    Welcome to the profound world of Anandathirumanam.com! We
                    are honored to be recognized as one of the most frequently
                    visited and highly preferred matrimonial sites. With our
                    ample database of profiles, we specialize in perfect
                    matchmakings, handy navigation, and commendable services.
                    Our dedication has placed us among the foremost players in
                    the field of Tamil Matrimonials.
                  </p>
                </div>
                {/* <div className="footer-contact">
                  <div className="icon">
                    <i className="fas fa-headphones" />
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="tel:919360631738">+91 9360631738</a>
                    </h4>
                    <span>Call Now</span>
                  </div>
                </div> */}
                {/* <div className="footer-social">
                  <ul>
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            {/* <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Our Policies</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/contacts">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Terms and Conditions</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Editorial Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">IP Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Grievance Redressal Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Our Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Quick Links</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/brides">Brides</Link>
                    </li>
                    <li>
                      <Link to="/grooms">Grooms</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Terms and Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">About Gounder</h4>
                <div className="footer-text">
                  <p>
                    Gounder is a title used by various communities in the Indian
                    state of Tamil Nadu. It may refer to various unrelated
                    communities such as Vettuva Gounder,Kongu Vellalar, Thuluva
                    Vellalar, Urali Gounders, Kurumba, Vanniyars, Vokkaligas.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Address</h4>
                <div className="fw-link">
                  <ul className="addRessI">
                    <li>
                      <i className="fas fa-map-marker-alt"></i> Trichy Main
                      road, Vellakoil - 638 111. Thirupur, Tamilnadu, India
                    </li>
                    <li>
                      <i className="fas fa-phone-alt"></i> +91 6381586891
                    </li>
                    <li>
                      <i className="fas fa-phone-alt"></i> +91 9342746403
                    </li>

                    <li>anandhathirumanam@gmail.com</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Instagram</h4>
                <div className="fw-insta">
                  <ul>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img01.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img02.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img03.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img04.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img05.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img06.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copyright-text">
                <p>Copyright © 2024 Ananda Thirumanam. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
