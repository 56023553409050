import React from "react";
import MainSlider from "../components/MainSlider";
import Counter from "../components/Counter";
import Testimonial from "../components/Testimonial";
import HomeGroom from "../components/HomeGroom";
import HomeBride from "../components/HomeBride";

function Home() {
  return (
    <main>
      <MainSlider />
      {/* <Find/> */}
      {/* <Counter />
      <Adoption /> */}
      {/* <BreedServices />
      <Faq afterElment="faq-area" /> */}
      {/* <Brand /> */}
      <HomeGroom />
      <Counter />
      <HomeBride />
      <Testimonial />
      {/* <BlogsHome />*/}
      {/* <Newsletter /> */}
    </main>
  );
}

export default Home;
