import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

function GroomList() {
  const [hGroom, setAllhGroom] = useState(null);
  const builder = imageUrlBuilder(sanityClient);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "members" && gender == "Male"]{
        title,
        slug,
        _id,
        name,
        gender,
        height,
        education,
        dob,
        photo{
          asset->{
          _id,
          url
        },
        crop,
				hotspot,
				alt,
      }
    }`
      )
      .then((data) => setAllhGroom(data))
      .catch(console.error);
  }, []);
  if (!hGroom) {
    return (
      <>
        <h1>Loading....</h1>
      </>
    );
  }
  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">GROOMS</h5>
              <h2 className="title">
                Search your Life Partner with Just a Few Details
              </h2>
              <p>
                Are you ready to find the perfect groom who will walk beside you
                through life's journey? Look no further than AnandaThirumanam,
                your trusted companion in the quest for matrimonial happiness.
                Our platform offers a diverse array of eligible grooms, each
                embodying qualities that make them ideal life partners.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center galCon">
          {hGroom &&
            hGroom.map((item, index) => (
              <div className=" galImg" key={index}>
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src={urlFor(item.photo)} alt="" />
                    <Link to={"/members-details/" + item._id} className="btn">
                      More Details <img src="/img/icon/w_pawprint.png" alt="" />
                    </Link>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <Link to={"/members-details/" + item._id}>
                        {item.name}
                      </Link>
                    </h4>
                    <div className="adoption-meta adoption-rating">
                      <ul>
                        <li className="price">
                          Web Id : <span>{item.title}</span>
                        </li>

                        <li className="price">
                          <i className="fas fa-book" />
                          Education :<span>{item.education}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="price">
                          <i className="far fa-calendar-alt" /> Age :{" "}
                          <span>
                            {new Date().getFullYear() -
                              new Date(item.dob).getFullYear()}
                          </span>
                        </li>
                        <li className="price">
                          Height : <span>{item.height}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default GroomList;
