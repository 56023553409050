import React from "react";
import ContactBreadCumb from "../components/contacts/ContactBreadCumb";
import ContactForm from "../components/contacts/ContactForm";

function ContactPage() {
  return (
    <main>
      <ContactBreadCumb />
      <ContactForm />
      <section className="mb-100">
        <iframe
          style={{ border: "none" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.2335326567354!2d77.71155867451921!3d10.945723956049244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba99ae5872580d3%3A0xfa5250a845c65fd2!2swww.anandathirumanam.com!5e0!3m2!1sen!2sin!4v1709189697879!5m2!1sen!2sin"
          width="100%"
          height="550"
        ></iframe>
      </section>
    </main>
  );
}

export default ContactPage;
