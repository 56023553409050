import React from "react";
import { Link } from "react-router-dom";

function AdoptionShop() {
  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Meet the animals</h5>
              <h2 className="title">Puppies Waiting for Adoption</h2>
              <p>
                Are you ready to find the perfect groom who will walk beside you
                through life's journey? Look no further than AnandaThirumanam,
                your trusted companion in the quest for matrimonial happiness.
                Our platform offers a diverse array of eligible grooms, each
                embodying qualities that make them ideal life partners.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb01.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  Adoption <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Mister Tartosh</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">Siberian Husky</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> Birth : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                      Total Price : <span>Free</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb02.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  Adoption <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Charlie</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">Golden Retriever</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> Birth : 2020
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                      Total Price : <span>$30</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb03.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  Adoption <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Alessia Max</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">German Sherped</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> Birth : 2020
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                      Total Price : <span>$29</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb04.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  Adoption <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Canadian</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">German Sherped</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> Birth : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                      Total Price : <span>$39</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb05.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  Adoption <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Entertainment</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">Siberian Husky</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> Birth : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                      Total Price : <span>Free</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb06.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  Adoption <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Dangerous</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">Golden Retriever</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> Birth : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                      Total Price : <span>Free</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptionShop;
