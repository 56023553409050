import React from 'react';
import ShopDetailsBreadcumb from '../components/shopdetails/ShopDetailsBreadcumb' 
import ShopDetailsArea from '../components/shopdetails/ShopDetailsArea'

function ShopDetailsPage() {
  return (
	  <main>
	  <ShopDetailsBreadcumb/>
	  <ShopDetailsArea/>
	  </main>
  )
}
export default ShopDetailsPage;
