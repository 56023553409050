import React from "react";
import { Link } from "react-router-dom";
import GroomList from "../components/GroomList";

function Groom() {
  return (
    <main>
      <section
        className="breadcrumb-area breadcrumb-bg"
        style={{ backgroundImage: 'url("img/bg/groom_page_bg.jpg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="title">Grooms</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Grooms
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GroomList />
    </main>
  );
}

export default Groom;
