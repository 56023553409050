import React from "react";
import { Link } from "react-router-dom";

function Adoption() {
  return (
    <section className="adoption-area mt-100 mt-mobile-0">
      <div className="container">
        <div className="row align-items-center  justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
            <div className="adoption-img">
              <img src="/img/images/about_ananda.jpg" alt="" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="adoption-content">
              <h2 className="title">
                Welcome to <span>Ananda Thirumanam</span>
              </h2>
              <p style={{ textAlign: "justify" }}>
                Welcome to the profound world of Anandathirumanam one of the
                most frequently visited and highly preferred matrimonial site.
                Providing an ample database of profiles, we are immensely known
                for the perfect matchmakings, handy navigation and commendable
                services, we have been able to establish ourselves among the
                foremost players in the field of Tamil Matrimonials.So feel free
                to search for prospective bride or bridegroom on the basis of
                mother tongue, country, religion, caste, occupation etc. from
                the immense database of profiles.
                <br />
                Anandathirumanam.com is one of the foremost and vivid Tamil
                Matrimonial Site, providing ample database of more number of
                prospective brides and grooms profiles. Due to the sanguine and
                serviceable approach of its talented and dedicated
                professionals, Anandathirumanam.com has successfully erected a
                vast list of satisfied clients across the boundaries.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="adoption-shape">
        <img src="/img/images/adoption_shape.png" alt="" />
      </div>
    </section>
  );
}

export default Adoption;
