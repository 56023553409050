import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function BreederContent() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_id == $slug]{
          title,
        slug,
        _id,
        name,
        property,
        business,
        gender,
        brother,
        height,
        Weight,
        education,
        dob,
        pname,
        income,
        shevai,
        rahu,
        star,
        rasi,
        caste,
        scaste,
        photo{
          asset->{
          _id,
          url
        },        
        crop,
				hotspot,
				alt,
      },
      horoscope{
        asset->{
        _id,
        url
      },        
      crop,
      hotspot,
      alt,
    }
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <div className="breeder-dog-info mt-0">
                <h2 className="title">Bio-Data</h2>
                <div className="row">
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Web id:</h6>
                      <span>{postData.title}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Name:</h6>
                      <span>{postData.name}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Gender:</h6>
                      <span>{postData.gender}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Parents name:</h6>
                      <span>{postData.pname}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Education:</h6>
                      <span>{postData.education}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Month Income:</h6>
                      <span>{postData.income}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Property:</h6>
                      <span>{postData.property}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Business / Job:</h6>
                      <span>{postData.business}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Date of Birth:</h6>
                      <span>
                        {new Date(postData.dob).getDate() +
                          "/" +
                          (new Date(postData.dob).getMonth() + 1) +
                          "/" +
                          new Date(postData.dob).getFullYear()}
                      </span>
                      {/* <span>
                        {new Date(postData.dob).getDate() +
                          "/" +
                          new Date(postData.dob).getMonth() +
                          1 +
                          "/" +
                          new Date(postData.dob).getFullYear()}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Birth Time:</h6>
                      <span>{new Date(postData.dob).toLocaleTimeString()}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Age:</h6>
                      <span>
                        {new Date().getFullYear() -
                          new Date(postData.dob).getFullYear()}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Height:</h6>
                      <span>{postData.height}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Weight:</h6>
                      <span>{postData.Weight}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Shevai:</h6>
                      <span>{postData.shevai}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Rahu, Kethu:</h6>
                      <span>{postData.rahu}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Star:</h6>
                      <span>{postData.star}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Rasi:</h6>
                      <span>{postData.rasi}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Caste:</h6>
                      <span>{postData.caste}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Sub caste:</h6>
                      <span>{postData.scaste}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>Brother / Sister:</h6>
                      <span>{postData.brother}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="breeder-sidebar">
              <div className="widget breeder-widget">
                <div className="breeder-widget-title mb-20">
                  <h5 className="title">Photo</h5>
                </div>
                <div className="profileImg">
                  <a href={urlFor(postData.photo)} target="_blank">
                    <img src={urlFor(postData.photo)} alt="profileImage" />
                  </a>
                  <a
                    href={urlFor(postData.horoscope)}
                    target="_blank"
                    className="btn dcm"
                  >
                    View Horoscope
                  </a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BreederContent;
