import React from "react";
import AdoptionShop from "../components/AdoptionShop";
import Faq from "../components/Faq";
import BreedServices from "../components/BreedServices";
import NewsLetter from "../components/NewsLetter";
import { Link } from "react-router-dom";
import Adoption from "../components/Adoption";
import Testimonial from "../components/Testimonial";
import Counter from "../components/Counter";

function About() {
  return (
    <main>
      <>
        <section
          className="breadcrumb-area breadcrumb-bg"
          style={{ backgroundImage: 'url("img/bg/page_bg.jpg")' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-content">
                  <h2 className="title">About Us</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About Us
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Adoption />
      <Counter />
      <Testimonial />
    </main>
  );
}

export default About;
