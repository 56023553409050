import React from "react";
import ContactAddress from "./ContactAddress";

function ContactForm() {
  const contForm = (e) => {
    e.preventDefault();
    alert(
      "Thank you for reaching out to us! Your message has been successfully received."
    );
    e.target.reset();
  };
  return (
    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-8 order-2 order-lg-0">
              <div className="contact-title mb-20">
                <h5 className="sub-title">Contact Us</h5>
                <h2 className="title">
                  Let's Talk Question<span>.</span>
                </h2>
              </div>
              <div className="contact-wrap-content">
                <p>
                  For any inquiries, assistance, or feedback, please don't
                  hesitate to reach out to us through the following channels
                </p>
                <form
                  className="contact-form"
                  onSubmit={contForm}
                  method="post"
                >
                  <div className="form-grp">
                    <label htmlFor="name">
                      Your Name <span>*</span>
                    </label>
                    <input type="text" required id="name" placeholder="Name" />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="email">
                      Your Email <span>*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      required
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="email">
                      Phone Number <span>*</span>
                    </label>
                    <input
                      type="number"
                      id="email"
                      required
                      placeholder="Mobile No"
                    />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="message">
                      Your Message <span>*</span>
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message..."
                      required
                      defaultValue={""}
                    />
                  </div>

                  <button type="submit" className="btn rounded-btn">
                    Send Now
                  </button>
                </form>
              </div>
            </div>

            <ContactAddress />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
