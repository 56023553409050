import React from "react";
import { Link } from "react-router-dom";
import BrideList from "../components/BrideList";

function Brides() {
  return (
    <main>
      <section
        className="breadcrumb-area breadcrumb-bg"
        style={{ backgroundImage: 'url("img/bg/bride_page_bg.jpg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="title">Brides</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Brides
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BrideList />
    </main>
  );
}

export default Brides;
