import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

function HomeBride() {
  const [hGroom, setAllhGroom] = useState(null);
  const builder = imageUrlBuilder(sanityClient);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "members" && gender == "Female"] | order(_createdAt asc) [0..5]{
        title,
        slug,
        _id,
        name,
        gender,
        height,
        education,
        dob,
        photo{
          asset->{
          _id,
          url
        },
        crop,
				hotspot,
				alt
      }
    }`
      )
      .then((data) => setAllhGroom(data))
      .catch(console.error);
  }, []);
  if (!hGroom) {
    return (
      <>
        <h1>Loading....</h1>
      </>
    );
  }
  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">BRIDES</h5>
              <h2 className="title">Search your Life Partner</h2>
              <p>
                At Anandathirumanam, we believe in empowering brides to find
                their perfect match with confidence and grace. Our platform
                offers a curated selection of profiles, personalized
                matchmaking, and expert guidance to help brides embark on their
                journey to lifelong happiness.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center galCon">
          {hGroom &&
            hGroom.map((item, index) => (
              <div className=" galImg" key={index}>
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src={urlFor(item.photo)} alt="" />
                    <Link to={"/members-details/" + item._id} className="btn">
                      More Details <img src="/img/icon/w_pawprint.png" alt="" />
                    </Link>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <Link to={"/members-details/" + item._id}>
                        {item.name}
                      </Link>
                    </h4>
                    <div className="adoption-meta adoption-rating">
                      <ul>
                        <li className="price">
                          Web Id : <span>{item.title}</span>
                        </li>

                        <li className="price">
                          <i className="fas fa-book" />
                          Education :<span>{item.education}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="price">
                          <i className="far fa-calendar-alt" /> Age :{" "}
                          <span>
                            {new Date().getFullYear() -
                              new Date(item.dob).getFullYear()}
                          </span>
                        </li>
                        <li className="price">
                          Height : <span>{item.height}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/brides" className="btn center">
            More Brides <img src="/img/icon/w_pawprint.png" alt=""></img>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default HomeBride;
